<template lang="">
    <div class="w1500 ht100 box jcsb">
    <easy-player class="w500 h300" :video-url="videoUrl" :live="true"></easy-player>
    <LivePlayer
    class="livenvr-player w500 h400"
    ref="liveqing-player"
    :videoUrl="videoUrl"
/>
<video id="videoPlayMap" class="video-js w500 h300 vjs-default-skin" preload="auto">
    <source :src="videoUrl" type="application/x-mpegURL">
</video>
</div>  
</template>
<script>
import EasyPlayer from '@easydarwin/easyplayer'
import LivePlayer from "@liveqing/liveplayer";
import videojs from 'video.js'
import 'videojs-contrib-hls'
import "@videojs/http-streaming"
export default {
    data() {
        return {
            videoUrl: "http://sh.mspace.mmcuav.cn:1945/live/paotou.m3u8",
            options: {
                autoplay: true, //自动播放
                controls: true, //用户可以与之交互的控件
                loop: false, //视频一结束就重新开始
                muted: true, //默认情况下将使所有音频静音
                playsinline: true,
                webkitPlaysinline: true,
                // aspectRatio:"16:9",//显示比率
                playbackRates: [0.5, 1, 1.5, 2],
                language: "zh-CN",
                fullscreen: {
                    options: { navigationUI: "hide" },
                },
            },
        }
    },
    methods: {
        init() {
            // 播放器初始化
            this.$nextTick(() => {
                this.player = videojs('videoPlayMap', this.options, function () {
                    this.play() // 自动播放
                })
            })
        },
    },
    created() {
        this.init()
    },
    components: { EasyPlayer, LivePlayer }
}
</script>
<style lang="scss" scoped>
.box {
    display: flex;
    justify-content: space-around;
}
</style>